import React, { useState } from "react";
import navlogo from "../../../assets/icons/navLogo.svg";
import { NavLink } from "react-router-dom";
import DropDownBox from "../../DropDownBox/DropDownBox";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectUserIsLoggedIn,
} from "../../../features/auth/authSlice";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
  Container,
} from "reactstrap";
import "./NavBar.css";
import { getCandidateProfilePictureUrl } from "../../../utils/methods";
import profilPic from "../../../assets/icons/profile.svg";
import { useGetCandidateDetailsQuery } from "../../../features/auth/authApiSlice";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const {
    data: candidateDetails,
    isLoading: candidateDetailsIsLoading,
    error: candidateDetailsError,
  } = useGetCandidateDetailsQuery(undefined, { skip: !isLoggedIn });

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar className="navBar" fixed="top" expand="md">
      <Container className="d-md-flex">
        <NavbarBrand>
          <img id="navLogo" src={navlogo} alt="nav logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} style={{ float: "right" }} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto navLinks" navbar>
            <NavItem>
              <NavLink
                to={"/find-jobs"}
                className={({ isActive }) => (isActive ? "navlinkActive" : "")}
                style={({ isActive }) => {
                  return {
                    textDecoration: "none",
                    color: isActive ? "white" : "#A29ED5",
                  };
                }}
              >
                Find Jobs
              </NavLink>
            </NavItem>
            {isLoggedIn && (
              <>
                <NavItem>
                  <NavLink
                    to={"/applied-jobs"}
                    className={({ isActive }) =>
                      isActive ? "navlinkActive" : ""
                    }
                    style={({ isActive }) => {
                      return {
                        textDecoration: "none",
                        color: isActive ? "white" : "#A29ED5",
                      };
                    }}
                  >
                    Applied Jobs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to={"/resources"}
                    className={({ isActive }) =>
                      isActive ? "navlinkActive" : ""
                    }
                    style={({ isActive }) => {
                      return {
                        textDecoration: "none",
                        color: isActive ? "white" : "#A29ED5",
                      };
                    }}
                  >
                    Resources
                  </NavLink>
                </NavItem>
              </>
            )}

            <NavItem>
              <NavLink
                to={"/about-us"}
                className={({ isActive }) => (isActive ? "navlinkActive" : "")}
                style={({ isActive }) => {
                  return {
                    textDecoration: "none",
                    color: isActive ? "white" : "#A29ED5",
                  };
                }}
              >
                About Us
              </NavLink>
            </NavItem>

            {isLoggedIn ? (
              <NavItem>
                <DropDownBox>
                  <div className="user-photo">
                    <img
                      className="avatar"
                      src={
                        candidateDetails?.guid
                          ? getCandidateProfilePictureUrl(
                              candidateDetails?.guid
                            )
                          : profilPic
                      }
                      title="Profile picture"
                    />
                  </div>
                </DropDownBox>
              </NavItem>
            ) : (
              <NavLink className="buttonSmall" to="/login">
                Let's get Started!
              </NavLink>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
