import AppliedJobs from "./pages/AppliedJobs/AppliedJobs";
import Resources from "./pages/ResourcesPage/Resources";
import UserProfile from "./pages/UserProfile/UserProfile";

const AppRoutes = [
  {
    path: "/update-profile",
    component: <UserProfile update={true} />,
  },

  {
    path: "/applied-jobs",
    component: <AppliedJobs />,
  },
  {
    path: "/resources",
    component: <Resources />,
  },
];

export default AppRoutes;
