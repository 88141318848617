import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./JobInfo.css";
import { useSelector } from "react-redux";
import ToastWrapper, {
  errorToast,
  successToast,
} from "../ToastWrapper/ToastWrapper";
import { selectUserIsLoggedIn } from "../../../features/auth/authSlice";
import {
  useApplyJobMutation,
  useGetJobByGuidQuery,
} from "../../../features/jobs/jobSlice";

import DotSpinner from "../LoadingSpinner/DotSpinner";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { formatToCurrency } from "../../../utils/methods";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  useGetAttachmentsQuery,
  useRemoveCandidateAttachmentMutation,
} from "../../../features/candidate/candidateSlice";
import useGetAttachment from "../../../hooks/useGetAttachment";
import toast from "react-hot-toast";
import pdfIcon from "../../../assets/icons/pdf.svg";
import closeIcon from "../../../assets/icons/close.svg";
import uploadIcon from "../../../assets/icons/upload-white.svg";
import viewIcon from "../../../assets/icons/view.svg";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import shareIcon from "../../../assets/icons/shareIcon.svg";
import applyIcon from "../../../assets/icons/applyIcon.svg";
import pdfIconLight from "../../../assets/icons/pdfLight.svg";
import {
  useAddAttachmentMutation,
  useAddCandidateAttachmentMutation,
} from "../../../features/candidate/candidateSlice";
import { attachmentTypes } from "../../../enums/attachmentTypes";
import { useGetCandidateDetailsQuery } from "../../../features/auth/authApiSlice";

const JobInfo = ({ jobGuid, tenant }) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectUserIsLoggedIn);

  const getFile = useGetAttachment();
  const [candidate, setCandidate] = useState({});
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [fileTypeId, setFileTypeId] = useState(null);
  const [selectedJob, setSelectedJob] = useState({});
  const [answers, setAnswers] = useState([]);
  const [files, setFiles] = useState([]);
  const [resume, setResume] = useState(null);
  const [coverLetterGuid, setCoverLetterGuid] = useState(null);

  const [
    addAttachment,
    { isLoading: addAttachmentIsLoading, addAttachmentIsSuccess },
  ] = useAddAttachmentMutation();

  const [
    addCandidateAttachment,
    {
      isLoading: addCandidateAttachmentIsLoading,
      addCandidateAttachmentIsSuccess,
    },
  ] = useAddCandidateAttachmentMutation();

  const [
    removeCandidateAttachment,
    {
      isLoading: removeCandidateAttachmentIsLoading,
      removeCandidateAttachmentIsSuccess,
    },
  ] = useRemoveCandidateAttachmentMutation();

  const {
    data: candidateDetails,
    isLoading: candidateDetailsIsLoading,
    error: candidateDetailsError,
  } = useGetCandidateDetailsQuery(undefined, { skip: !isLoggedIn });

  const {
    data: jobDetails,
    isLoading: jobDetailsIsLoading,
    isFetching: jobDetailsIsFetching,
    isError: jobDetailsIsError,
    isSuccess: jobDetailsIsSuccess,
    error: jobDetailsError,
  } = useGetJobByGuidQuery(
    { guid: jobGuid, tenant },
    {
      skip: !jobGuid || !tenant,
    }
  );
  const {
    data: attachments,
    isLoading: attachmentsIsLoading,
    isFetching: attachmentsIsFetching,
    isError: attachmentsIsError,
    isSuccess: attachmentsIsSuccess,
    error: attachmentsError,
  } = useGetAttachmentsQuery();

  useEffect(() => {
    if (jobDetails) {
      setSelectedJob(jobDetails);
      jobDetails?.applicationQuestions?.reduce((acc, { guid }) => {
        acc[guid] = false;
        return acc;
      }, {});
    }
    if (jobDetailsError) {
      errorToast(
        jobDetailsError?.data?.Message || jobDetailsError?.data?.title
      );
    }
  }, [jobDetails]);

  useEffect(() => {
    if (candidateDetails) {
      setCandidate(candidateDetails);
    }
    if (candidateDetailsError) {
      toast.error(
        candidateDetailsError?.message || candidateDetailsError?.data?.Message
      );
    }
  }, [candidateDetails, candidateDetailsError]);

  const getAttachment = async (guid) => {
    try {
      const result = await getFile(guid);
      const url = window.URL.createObjectURL(result?.data);
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    if (attachments) {
      setFiles(attachments);
    }
  }, [attachments]);

  const [applyJob, { isLoading, isSuccess, isError, error }] =
    useApplyJobMutation();

  const handleApplyClick = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    if (!candidate?.isCompletedProfile) {
      errorToast(
        "Please complete your profile by adding your skills before applying for a job."
      );
      const navigationTimeout = setTimeout(() => {
        navigate("/update-profile");
      }, 2000);
      return;
    }
    handleReset();
    togglePopup();
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      errorToast(
        `File size should be less than ${MAX_FILE_SIZE / (1024 * 1024)} MB`
      );
      return;
    }

    if (type === attachmentTypes.RESUME) {
      setSelectedFile(file);
    }
    if (type === attachmentTypes.COVER_LETTER) {
      setCoverLetter(file);
    }
    setFileTypeId(type);
  };

  const handleSelectChange = (guid, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [guid]: value,
    }));
  };

  const handleReset = () => {
    setResume(null);
    setCoverLetterGuid(null);
    setAnswers([]);
  };

  const handleSubmit = async () => {
    if (selectedJob?.isCoverLetterRequired && !coverLetterGuid) {
      errorToast(
        "This job application requries a cover letter with your resume...!"
      );
      return;
    }
    try {
      const data = {
        resumeGuid: resume,
        coverLetterGuid: coverLetterGuid,
        schema: tenant,
        applicationQuestionAnswers: Object.keys(answers).map((key) => ({
          guid: key,
          answer: JSON.parse(answers[key]),
        })),
      };
      const response = applyJob({ guid: jobGuid, data }).unwrap();
      toast.promise(response, {
        loading: "Appling...",
        success: "Applied Successfully...!",
        error: "Something Went Wrong...!",
      });
      await response;
      setResume(null);
      setCoverLetterGuid(null);
    } catch (error) {
      errorToast(error?.data?.Message || error?.data?.title || error?.Message);
    }
    handleReset();
    togglePopup();
  };

  const addAttachmentOnClick = async () => {
    try {
      const requestPromise = addAttachment({
        file: fileTypeId == attachmentTypes.RESUME ? selectedFile : coverLetter,
      }).unwrap();

      toast.promise(requestPromise, {
        loading: "Uploading File...",
        success: "File Uploaded Successfully...!",
        error: (err) =>
          `File Uploading failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });

      const response = await requestPromise;
      if (response?.error) {
        return;
      }
      if (response?.guid) {
        await addCandidateAttachmentOnClick(response?.guid);
      }
      setSelectedFile(null);
      setCoverLetter(null);
      setFileTypeId(null);
    } catch (error) {
      console.log(
        error?.data?.Message || "Something Went Wrong while uploading...!"
      );
    }
  };

  const addCandidateAttachmentOnClick = async (attachmentGuid) => {
    try {
      const response = await addCandidateAttachment({
        attachmentTypeId: fileTypeId,
        attachmentGuid: attachmentGuid,
      }).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }

      successToast("Attachment uploaded Successfully..!");
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  const handleUpload = async () => {
    if (selectedFile || coverLetter) {
      const attachmentResponse = await addAttachmentOnClick();
      if (attachmentResponse?.error) {
        errorToast(attachmentResponse?.Message || "Something Went wrong");
        return;
      }
    }
  };

  useEffect(() => {
    if (!isPopupOpen) {
      setSelectedFile(null);
      setCoverLetter(null);
      setFileTypeId(null);
    }
  }, [isPopupOpen]);

  const handleDeleteAttachment = async (guid) => {
    try {
      const response = await removeCandidateAttachment(guid).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }
      successToast("Attachment Removed Successfully..!");
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(`${window.location.origin}/#/find-jobs?jobid=${jobGuid}`)
      .then(() => {
        successToast("Job link copied to clipboard!");
      })
      .catch((error) => {
        errorToast("Failed to copy link.");
        console.error("Error copying link:", error);
      });
  };

  return (
    <div
      className="job-desc-container pe-3 m-1"
      style={{ maxHeight: "70vh", overflow: "auto", position: "relative" }}
    >
      {(jobDetailsIsFetching || candidateDetailsIsLoading) && (
        <LoadingSpinner />
      )}
      <ToastWrapper />
      {selectedJob ? (
        <>
          <section className="pt-3 border-bottom mb-3 job-title-section">
            <div className="d-flex justify-content-between mb-2">
              <div>
                <h5>{selectedJob?.title}</h5>
                <p>{selectedJob?.clientName}</p>
                <p>
                  {selectedJob?.isSalaryDisplayOnJobPosting && (
                    <>
                      {formatToCurrency(selectedJob?.minSalary)}
                      {" - "}
                      {formatToCurrency(selectedJob?.maxSalary)}
                    </>
                  )}
                </p>
              </div>
              <div>
                <button
                  className="btn btn-primary btn-basic btn-apply p-2 w-100 mb-1 d-flex align-items-center justify-content-even gap-1"
                  onClick={handleApplyClick}
                >
                  <img src={applyIcon} alt="" width={20} />
                  Apply for this job
                </button>
                <button
                  className="btn btn-success btn-basic btn-apply p-2 w-100 d-flex align-items-center justify-content-even gap-1"
                  onClick={handleCopyLink}
                >
                  <img src={shareIcon} alt="" width={20} />
                  Share Job Link
                </button>
              </div>
            </div>
          </section>
          <section>
            <div>
              <h6>About the Job</h6>
              <p>
                {selectedJob?.description && parse(selectedJob?.description)}
              </p>
            </div>

            {selectedJob?.roleDescription && (
              <div>
                <h6>The Role</h6>
                <p>{selectedJob?.roleDescription}</p>
              </div>
            )}
            <div>
              <h6>Your Responsibilities Will Include</h6>
              <p>
                {selectedJob?.responsibilities &&
                  parse(selectedJob?.responsibilities)}
              </p>
            </div>
            <div>
              <h6>Required Skills</h6>
              <ul>
                {selectedJob?.skills?.map((skill) => (
                  <li key={skill?.guid}>{skill?.name}</li>
                ))}
              </ul>
            </div>
          </section>
        </>
      ) : (
        "No don't have any applied jobs"
      )}

      <Modal
        isOpen={isPopupOpen}
        toggle={togglePopup}
        className="apply-job-modal"
        size="lg"
        centered
        scrollable
      >
        <ModalHeader toggle={togglePopup} className="apply-job-modalheader">
          {selectedJob?.title} | {selectedJob?.company?.name}
        </ModalHeader>
        <ModalBody className="modal-body">
          <form>
            <div className="mb-0">
              <h6 className="mt-0">Basic Information</h6>
              <hr className="my-1 mb-2" />
              <div className="d-flex justify-content-between">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label mb-0 mt-2">
                    First Name
                  </label>

                  <p className="fw-bold fs-6">{candidate?.firstName}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="lastName" className="form-label mb-0 mt-2">
                    Last Name
                  </label>

                  <p className="fw-bold fs-6">{candidate?.lastName}</p>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label mb-0 mt-2">
                    Email
                  </label>
                  <p className="fw-bold fs-6">{candidate?.email}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="lastName" className="form-label mb-0 mt-2">
                    Mobile
                  </label>
                  <p className="fw-bold fs-6">{candidate?.mobile}</p>
                </div>
              </div>

              <h6 className="mt-3">Address</h6>
              <hr className="my-1 mb-2" />
              <div className="d-flex justify-content-between">
                <div className="custom-form-group">
                  <p className="fw-bold fs-6">{candidate?.address}</p>
                </div>
              </div>

              <h6 className="mt-3">Professional Details</h6>
              <hr className="my-1 mb-2" />
              <div className="d-flex justify-content-between">
                <div className="custom-form-group">
                  <label htmlFor="skills" className="form-label mb-0 mt-2">
                    Skills
                  </label>
                  <div className="skills">
                    {candidate?.skills?.map((skill) => (
                      <div key={skill.id} className="tag">
                        {skill.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {selectedJob?.applicationQuestions?.length > 0 && (
                <div key={selectedJob?.applicationQuestions?.guid}>
                  <h6 className="mt-3">Application Question</h6>
                  <hr className="my-1 mb-2" />

                  <div>
                    {selectedJob?.applicationQuestions?.map(
                      ({ question, guid }) => (
                        <div
                          key={guid}
                          className="d-flex align-items-center justify-content-between gap-2 py-2"
                        >
                          <span>{question}</span>
                          <label htmlFor={`applicationQuestion-${guid}`}>
                            <select
                              id={`applicationQuestion-${guid}`}
                              value={answers[guid] || ""}
                              onChange={(e) =>
                                handleSelectChange(guid, e.target.value)
                              }
                              className="form-select"
                              style={{
                                minWidth: "200px",
                              }}
                            >
                              <option value="">Select</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              <h6 className="mt-3">Add/Select a Resume</h6>
              <hr className="my-1 mb-2" />
              <div className="row">
                {files
                  ?.filter((file) => file?.type?.id === attachmentTypes?.RESUME)
                  .map((file) => (
                    <div
                      key={file?.guid}
                      className="col-auto d-flex align-items-center mb-1"
                    >
                      <div className="form-check p-0">
                        <input
                          className="form-check-input pdf-checkbox"
                          type="radio"
                          name="flexRadioDefault"
                          id={`file-${file?.guid}`}
                          checked={resume === file?.guid}
                          onClick={() => {
                            setResume(file?.guid);
                          }}
                        />
                        <label
                          className={`form-check-label d-flex align-items-center flex-column gap-1 text-center ${
                            resume === file?.guid ? "selected-file" : ""
                          }`}
                          htmlFor={`file-${file?.guid}`}
                        >
                          <img
                            src={pdfIcon}
                            alt="pdf icon"
                            role="button"
                            className="pdf-icon"
                          />

                          <span
                            style={{
                              width: "100px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            title={file?.fileName}
                          >
                            {file?.fileName}
                          </span>
                        </label>
                      </div>
                      <div
                        className={`d-flex flex-column justify-content-start h-100 ${
                          resume === file?.guid ? "" : "d-none"
                        }`}
                      >
                        <button
                          type="button"
                          className="btn btn-sm remove-attachment p-0"
                          title="Delete File"
                          onClick={() =>
                            handleDeleteAttachment(file?.attachmentGuid)
                          }
                        >
                          <img src={deleteIcon} alt="" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm  view-file-btn p-0"
                          title="
                                View File"
                          onClick={() => getAttachment(file?.attachmentGuid)}
                        >
                          <img src={viewIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  ))}

                <div className="col-auto d-flex gap-1 align-items-start flex-column">
                  <div className="resume-form-group d-flex align-items-center gap-1">
                    <div className="file-input-container ms-3">
                      <label
                        className="file-upload-container custom-file-upload"
                        htmlFor="file-upload-resume"
                        title="Click Here to Upload Documents"
                      >
                        <input
                          type="file"
                          id="file-upload-resume"
                          accept="application/pdf"
                          onChange={(e) => {
                            handleFileChange(e, attachmentTypes.RESUME);
                          }}
                          required
                        />
                        {/* {addAttachmentIsLoading &&
                        fileTypeId === attachmentTypes.RESUME ? (
                          <DotSpinner />
                        ) : (
                        )} */}
                        <img
                          src={selectedFile ? pdfIcon : pdfIconLight}
                          alt="PDF"
                        />
                        <span>Browse Documents</span>
                        <span>*.PDF only</span>
                      </label>
                    </div>

                    {selectedFile && (
                      <div className="relative">
                        <button
                          className="btn btn-sm action-close-btn action-rounded"
                          onClick={() => setSelectedFile(null)}
                        >
                          <img src={closeIcon} alt="" />
                        </button>
                        <button
                          type="button"
                          onClick={handleUpload}
                          className="btn btn-sm action-check-btn action-rounded mx-1"
                        >
                          <img src={uploadIcon} alt="" width={20} height={19} />
                        </button>
                      </div>
                    )}
                  </div>
                  <span>{selectedFile && selectedFile.name}</span>
                </div>
              </div>
              {/* cover letter section */}
              {selectedJob?.isCoverLetterRequired ? (
                <>
                  <h6 className="mt-3">Add/Select a Cover Letter</h6>
                  <hr className="my-1 mb-2" />
                  <div className="row">
                    {files
                      ?.filter(
                        (file) =>
                          file?.type?.id === attachmentTypes?.COVER_LETTER
                      )
                      .map((file) => (
                        <div
                          key={file?.guid}
                          className="col-auto d-flex align-items-center gap-2 mb-1"
                        >
                          <div className="form-check p-0">
                            <input
                              className="form-check-input pdf-checkbox"
                              type="radio"
                              name="flexRadioDefault"
                              id={`file-${file?.guid}`}
                              checked={coverLetterGuid === file?.guid}
                              onClick={() => {
                                setCoverLetterGuid(file?.guid);
                              }}
                            />
                            <label
                              className={`form-check-label d-flex align-items-center flex-column gap-1 text-center ${
                                coverLetterGuid === file?.guid
                                  ? "selected-file"
                                  : ""
                              }`}
                              htmlFor={`file-${file?.guid}`}
                            >
                              <img
                                src={pdfIcon}
                                alt="pdf icon"
                                role="button"
                                className="pdf-icon"
                              />
                              <button
                                className="btn btn-small p-0 px-1 view-file-btn"
                                style={{
                                  width: "100px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                                type="button"
                                onClick={() =>
                                  getAttachment(file?.attachmentGuid)
                                }
                              >
                                {file?.fileName}
                              </button>
                            </label>
                          </div>
                          <div
                            className={`d-flex flex-column justify-content-start h-100 ${
                              coverLetterGuid === file?.guid ? "" : "d-none"
                            }`}
                          >
                            <button
                              type="button"
                              className="btn btn-sm remove-attachment p-0"
                              title="Delete File"
                              onClick={() =>
                                handleDeleteAttachment(file?.attachmentGuid)
                              }
                            >
                              <img src={deleteIcon} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm  view-file-btn p-0"
                              title="
                                View File"
                              onClick={() =>
                                getAttachment(file?.attachmentGuid)
                              }
                            >
                              <img src={viewIcon} alt="" />
                            </button>
                          </div>
                        </div>
                      ))}

                    <div className="col-auto d-flex gap-1 align-items-start flex-column">
                      <div className="resume-form-group d-flex align-items-center gap-1">
                        <div className="file-input-container ms-3">
                          <label
                            className="file-upload-container custom-file-upload"
                            htmlFor="file-upload-cover"
                            title="Click Here to Upload Documents"
                          >
                            <input
                              type="file"
                              id="file-upload-cover"
                              accept="application/pdf"
                              onChange={(e) => {
                                handleFileChange(
                                  e,
                                  attachmentTypes.COVER_LETTER
                                );
                              }}
                              required
                            />

                            <img
                              src={coverLetter ? pdfIcon : pdfIconLight}
                              alt="PDF"
                            />
                            <span>Browse Documents</span>
                            <span>*.PDF only</span>
                          </label>
                        </div>
                        {coverLetter && (
                          <div>
                            <button
                              className="btn btn-sm action-close-btn action-rounded"
                              onClick={() => setCoverLetter(null)}
                            >
                              <img src={closeIcon} alt="" />
                            </button>
                            <button
                              type="button"
                              onClick={handleUpload}
                              className="btn btn-sm action-check-btn action-rounded mx-1"
                            >
                              <img
                                src={uploadIcon}
                                alt=""
                                width={20}
                                height={19}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                      <span>{coverLetter && coverLetter.name}</span>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <div className="col-12 col-md-5">
            <button
              className="btn btn-basic footer-save-button"
              onClick={togglePopup}
              disabled={isLoading}
            >
              Close
            </button>
          </div>
          <div className="col-12 col-md-5">
            <button
              className="btn btn-basic footer-change-button"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <DotSpinner /> : "Apply for this Role"}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default JobInfo;
