import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import NavBar from "./components/common/NavBar/NavBar";
import Footer from "./components/common/Footer/Footer";
import AppRoutes from "./AppRoutes";
import LandingPage from "./pages/LandingPage/LandingPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RequireAuth from "./features/auth/RequireAuth";
import { Navigate } from "react-router-dom";
import FindJobs from "./pages/FindJobs/FindJobs";
import UserProfile from "./pages/UserProfile/UserProfile";

function App() {
  const location = useLocation();

  const pathsWithoutNavBar = ["/login"];

  const shouldHideNavBar = pathsWithoutNavBar.includes(location.pathname);

  return (
    <div className="app">
      {!shouldHideNavBar && <NavBar />}
      <main>
        <Routes>
          <Route path="/" element={<Navigate to={"/find-jobs"} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/about-us" element={<LandingPage />} />
          <Route path="/find-jobs" element={<FindJobs />} />
          <Route
            path="/create-profile"
            element={<UserProfile update={false} />}
          />
          <Route element={<RequireAuth />}>
            {AppRoutes.map((item, index) => (
              <Route key={index} path={item.path} element={item.component} />
            ))}
          </Route>
        </Routes>
      </main>
      {!shouldHideNavBar && <Footer />}
    </div>
  );
}

export default App;
