import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import "./DropDownBox.css";
import { ReactComponent as Profile } from "../../assets/icons/profile.svg";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectUserIsLoggedIn } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetCandidateDetailsQuery } from "../../features/auth/authApiSlice";

function DropDownBox({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const {
    data: candidateDetails,
    isLoading: candidateDetailsIsLoading,
    error: candidateDetailsError,
  } = useGetCandidateDetailsQuery(undefined, { skip: !isLoggedIn });

  const handleLogout = () => {
    navigate("/login");
    dispatch(logOut());
  };

  return (
    <UncontrolledDropdown group>
      <DropdownToggle
        className="dropToggle"
        style={{ borderRadius: "100%", background: "none", border: "none" }}
      >
        {children}
      </DropdownToggle>
      <DropdownMenu end style={{ borderRadius: "10px 0 0 10px", padding: 1 }}>
        <DropdownItem className="dropDownItems firstItem">
          <Profile width={16} height={16} className="dropDownImage" />
          {`${candidateDetails?.firstName || ""} ${
            candidateDetails?.lastName || ""
          }`}
        </DropdownItem>
        <DropdownItem
          className="dropDownItems secondItem"
          onClick={() => navigate("/update-profile")}
        >
          View/Edit Profile
        </DropdownItem>
        <DropdownItem
          className="dropDownItems thirdItem"
          onClick={handleLogout}
        >
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default DropDownBox;
