import { differenceInMonths } from "date-fns";

export function formatToCurrency(amount) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(amount);
}

export function getCandidateProfilePictureUrl(candidateGuid) {
  const baseUrl =
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_API_URL
      : process.env.PUBLIC_URL;
  return `${baseUrl}/v1/candidates/${candidateGuid}/profile-picture`;
}

export const getMonthYearRange = (from, to) => {
  if (!to) {
    return null;
  }

  const toDate = new Date(to);
  const fromDate = new Date(from);

  if (fromDate > toDate) {
    return null;
  }

  const totalMonths = differenceInMonths(toDate, fromDate);
  const months = totalMonths % 12;
  const years = Math.floor(totalMonths / 12);

  if (years === 0 && months === 0) {
    return null;
  }

  const yearPart = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "";
  const monthPart = months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "";

  return [yearPart, monthPart].filter(Boolean).join(" ");
};
