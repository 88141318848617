import React, { useEffect, useState } from "react";
import "./UserProfile.css";
import profilePicture from "../../assets/images/profile-picture.jpg";
import pdfIconLight from "../../assets/icons/pdfLight.svg";
import pdfIcon from "../../assets/icons/pdf.svg";
import closeIcon from "../../assets/icons/close.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import editIcon from "../../assets/icons/edit.svg";
import viewIcon from "../../assets/icons/view.svg";
import uploadIcon from "../../assets/icons/upload-white.svg";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";
import CandidateSkill from "../../components/CandidateSkill/CandidateSkill";
import {
  useGetCandidateDetailsQuery,
  useRegisterMutation,
  useUpdateProfileMutation,
} from "../../features/auth/authApiSlice";
import ToastWrapper, {
  errorToast,
} from "../../components/common/ToastWrapper/ToastWrapper";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectUserIsLoggedIn,
  setCredentials,
} from "../../features/auth/authSlice";
import {
  getCandidateProfilePictureUrl,
  getMonthYearRange,
} from "../../utils/methods";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import Multiselect from "multiselect-react-dropdown/dist";
import {
  useGetCompaniesQuery,
  useGetSkillsQuery,
  useGetWorkTypesQuery,
} from "../../features/staticData/staticData";
import {
  useAddCandidateExperienceMutation,
  useUpdateCandidateExperienceMutation,
  useRemoveCandidateExperienceMutation,
  useGetAttachmentsQuery,
  useAddAttachmentMutation,
  useAddCandidateAttachmentMutation,
  useRemoveCandidateAttachmentMutation,
} from "../../features/candidate/candidateSlice";
import { format } from "date-fns";
import { attachmentTypes } from "../../enums/attachmentTypes";
import useGetAttachment from "../../hooks/useGetAttachment";
import { ProfilePicture } from "../../components/ProfilePicture/ProfilePicture";

const UserProfile = ({ update }) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const currentUser = useSelector(selectCurrentUser);
  const [currentCandidateData, setCurrentCandidateData] = useState({
    guid: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    isCompletedProfile: true,
    currentCompany: {
      guid: "",
      name: "",
      industry: "",
      logo: "",
    },
    skills: [
      {
        id: 0,
        guid: "",
        name: "",
      },
    ],
    educations: [
      {
        guid: "",
        college: "",
        degree: "",
        major: "",
        gpa: 0,
        isUndergraduate: true,
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        guid: "",
        title: "",
        description: "",
        company: "",
        workType: {
          guid: "",
          name: "",
        },
        isCurrentlyWorkingOn: true,
        startDate: "",
        endDate: "",
        skills: [
          {
            id: 0,
            guid: "",
            name: "",
          },
        ],
      },
    ],
  });
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const [tab, setTab] = useState(1);
  const [againPassword, setAgainPassword] = useState("");
  const [selectedAttachmentGuid, setSelectedAttachmentGuid] = useState("");
  const [companies, setCompanies] = useState([]);
  const [workingTypes, setWorkingTypes] = useState([]);
  const [candidateResumes, setCandidateResumes] = useState([]);
  const [candidateCoverLetters, setCandidateCoverLetters] = useState([]);
  const [skills, setSkills] = useState([]);
  const [basicInformation, setBasicInformation] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    address: "",
  });

  const [experience, setExperience] = useState({
    title: "",
    description: "",
    company: "",
    workType: [],
    isCurrentlyWorkingOn: false,
    startDate: "",
    endDate: null,
    skills: [],
  });

  const [file, setFile] = useState(null);

  const [register] = useRegisterMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [addCandidateExperience] = useAddCandidateExperienceMutation();
  const [updateCandidateExperience] = useUpdateCandidateExperienceMutation();
  const [removeCandidateExperience] = useRemoveCandidateExperienceMutation();
  const [addAttachment] = useAddAttachmentMutation();
  const [addCandidateAttachment] = useAddCandidateAttachmentMutation();
  const [removeCandidateAttachment] = useRemoveCandidateAttachmentMutation();
  const getFile = useGetAttachment();
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: candidateDetails,
    isLoading: candidateDetailsIsLoading,
    error: candidateDetailsError,
  } = useGetCandidateDetailsQuery(undefined, { skip: !isLoggedIn });

  const { data: currentCompanies, error: currentCompaniesError } =
    useGetCompaniesQuery();

  const { data: candidateWorkingTypes, error: candidateWorkingTypesError } =
    useGetWorkTypesQuery();

  const { data: candidateSkills, error: candidateSkillsError } =
    useGetSkillsQuery();

  const { data: candidateAttachmentsData, error: candidateAttachmentsError } =
    useGetAttachmentsQuery(undefined, { skip: !isLoggedIn });

  useEffect(() => {
    if (candidateSkills) {
      setSkills(candidateSkills);
    }
    if (candidateSkillsError) {
      toast.error(
        candidateSkillsError?.message || candidateSkillsError?.data?.Message
      );
    }
  }, [candidateSkills, candidateSkillsError]);

  useEffect(() => {
    if (currentCompanies) {
      setCompanies(currentCompanies);
    }
    if (currentCompaniesError) {
      toast.error(
        currentCompaniesError?.message || currentCompaniesError?.data?.Message
      );
    }
  }, [currentCompaniesError, currentCompanies]);

  useEffect(() => {
    if (candidateWorkingTypes) {
      setWorkingTypes(candidateWorkingTypes);
    }
    if (candidateWorkingTypesError) {
      toast.error(
        candidateWorkingTypesError?.message ||
          candidateWorkingTypesError?.data?.Message
      );
    }
  }, [candidateWorkingTypesError, candidateWorkingTypes]);

  useEffect(() => {
    if (candidateDetails) {
      setCurrentCandidateData(candidateDetails);
      setBasicInformation({
        firstName: candidateDetails?.firstName,
        lastName: candidateDetails?.lastName,
        email: candidateDetails?.email,
        mobile: candidateDetails?.mobile,
        address: candidateDetails?.address,
      });
    }
    if (candidateDetailsError) {
      toast.error(
        candidateDetailsError?.message || candidateDetailsError?.data?.Message
      );
    }
  }, [candidateDetails, candidateDetailsError]);

  useEffect(() => {
    if (candidateAttachmentsData) {
      setCandidateResumes(
        candidateAttachmentsData?.filter(
          (atch) => atch?.type?.id === attachmentTypes.RESUME
        )
      );
      setCandidateCoverLetters(
        candidateAttachmentsData?.filter(
          (atch) => atch?.type?.id === attachmentTypes.COVER_LETTER
        )
      );
    }
    if (candidateAttachmentsError) {
      toast.error(
        candidateAttachmentsError?.message ||
          candidateAttachmentsError?.data?.Message
      );
    }
  }, [candidateAttachmentsData, candidateAttachmentsError]);

  const handleBasicInfoInputChange = (event) => {
    const { name, value } = event?.target;
    setBasicInformation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleExperienceInputChange = ({ name, value }) => {
    setExperience((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateBasicInformation = (info) => {
    const errors = [];

    if (!info.firstName.trim()) {
      errors.push("First name is required.");
    }

    if (!info.lastName.trim()) {
      errors.push("Last name is required.");
    }

    if (!info.email.trim()) {
      errors.push("Email is required.");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(info.email)) {
      errors.push("Invalid email format.");
    }

    if (!info.password.trim()) {
      errors.push("Password is required.");
    } else if (info.password.length < 6) {
      errors.push("Password must be at least 6 characters long.");
    }

    if (!info.address.trim()) {
      errors.push("Address is required.");
    }

    return errors;
  };

  const handleBasicInfoInputReset = () => {
    setBasicInformation({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      address: "",
    });
  };

  const handleBasicInfoInputResetToInitialValues = () => {
    setBasicInformation({
      firstName: currentCandidateData?.firstName,
      lastName: currentCandidateData?.lastName,
      email: currentCandidateData?.email,
      mobile: currentCandidateData?.mobile,
      address: currentCandidateData?.address,
    });
  };

  const handleExperienceInfoInputReset = () => {
    setExperience({
      title: "",
      description: "",
      company: "",
      workType: [],
      isCurrentlyWorkingOn: false,
      startDate: "",
      endDate: null,
      skills: [],
    });
  };

  const handleCreateProfile = async () => {
    try {
      const validationErrors = validateBasicInformation(basicInformation);
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => errorToast(error));
        return;
      }
      if (basicInformation.password !== againPassword) {
        errorToast("Passwords don't match. Please check again.");
        return;
      }
      const requestPromise = register(basicInformation).unwrap();
      toast.promise(requestPromise, {
        loading: "Creating Profile...",
        success: "Profile Created Successfully...!",
        error: (err) =>
          `Profile Creation failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
      const response = await requestPromise;
      if (response) {
        const userData = {
          guid: response?.guid,
          token: response?.token,
          email: response?.email,
          firstName: response?.firstName,
          lastName: response?.lastName,
          isCompletedProfile: response?.isCompletedProfile,
          tokenExpiary: response?.tokenExpiary,
        };
        dispatch(setCredentials(userData));
        // navigate("/find-jobs");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      if (!basicInformation?.firstName) {
        errorToast("First name is required.");
        return;
      }
      if (!basicInformation?.lastName) {
        errorToast("First name is required.");
        return;
      }

      const requestPromise = updateProfile(basicInformation).unwrap();
      toast.promise(requestPromise, {
        loading: "Updating Profile...",
        success: "Profile Updated Successfully...!",
        error: (err) =>
          `Profile Updating failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
      await requestPromise;
    } catch (error) {
      console.error(error);
    }
  };

  const validateExperience = (experience) => {
    const errors = [];

    if (!experience?.title || experience.title.trim() === "") {
      errors.push("Title is required.");
    }
    // if (!experience?.description || experience.description.trim() === "") {
    //   errors.push("Description is required.");
    // }
    // if (!experience?.company || experience.company.trim() === "") {
    //   errors.push("Company name is required.");
    // }
    if (!experience?.workType?.[0]?.guid) {
      errors.push("Work type is required.");
    }
    if (!experience?.startDate) {
      errors.push("Start date is required.");
    }
    // if (experience?.isCurrentlyWorkingOn === false && !experience?.endDate) {
    //   errors.push("End date is required if not currently working.");
    // }
    if (!experience?.skills || experience.skills.length === 0) {
      errors.push("At least one skill is required.");
    }

    return errors;
  };

  const handleAddWorkingExperience = async () => {
    const validationErrors = validateExperience(experience);

    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => errorToast(error));
      return;
    }

    const experienceDetails = {
      title: experience?.title,
      description: experience?.description,
      companyName: experience?.company,
      workTypeGuid: experience?.workType[0]?.guid,
      isCurrentlyWorkingOn: experience?.isCurrentlyWorkingOn,
      startDate: experience?.startDate,
      endDate: experience?.endDate,
      skills: experience?.skills?.map((sk) => sk?.guid),
    };

    try {
      const requestPromise = addCandidateExperience(experienceDetails).unwrap();
      toast.promise(requestPromise, {
        loading: "Adding work experience...",
        success: "Work experience added Successfully...!",
        error: (err) =>
          `Adding Work Experience failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
      handleExperienceInfoInputReset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteWorkingExperience = async (guid) => {
    try {
      const requestPromise = removeCandidateExperience(guid).unwrap();
      toast.promise(requestPromise, {
        loading: "Removing work experience...",
        success: "Work experience removed Successfully...!",
        error: (err) =>
          `Removing Work Experience failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditExperience = (exp) => {
    setExperience({
      guid: exp?.guid,
      title: exp?.title,
      description: exp?.description,
      company: exp?.company ?? "",
      workType: [exp?.workType],
      isCurrentlyWorkingOn: exp?.isCurrentlyWorkingOn,
      startDate: exp?.startDate,
      endDate: exp?.endDate,
      skills: exp?.skills,
    });
  };

  const handleUpdateWorkingExperience = async (guid) => {
    const validationErrors = validateExperience(experience);

    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => errorToast(error));
      return;
    }
    const experienceDetails = {
      title: experience?.title,
      description: experience?.description,
      companyName: experience?.company,
      workTypeGuid: experience?.workType[0]?.guid,
      isCurrentlyWorkingOn: experience?.isCurrentlyWorkingOn,
      startDate: experience?.startDate,
      endDate: experience?.endDate,
      skills: experience?.skills?.map((sk) => sk?.guid),
    };
    try {
      const requestPromise = updateCandidateExperience({
        guid,
        experienceDetails,
      }).unwrap();
      toast.promise(requestPromise, {
        loading: "Updating work experience...",
        success: "Work experience updated Successfully...!",
        error: (err) =>
          `Updating Work Experience failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
      handleExperienceInfoInputReset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddSkill = (selectedList) => {
    handleExperienceInputChange({
      name: "skills",
      value: selectedList,
    });
  };
  const handleRemoveSkill = (selectedList, selectedItem) => {
    handleExperienceInputChange({
      name: "skills",
      value: selectedList?.filter((sk) => sk !== selectedItem?.guid),
    });
  };

  const handleSelect = (name, selectedList, selectedItem) => {
    handleExperienceInputChange({
      name,
      value: [selectedItem],
    });
  };

  const handleDeSelect = (name, selectedList, selectedItem) => {
    handleExperienceInputChange({
      name,
      value: null,
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      toast.error(
        `File size should be less than ${MAX_FILE_SIZE / (1024 * 1024)} MB`
      );
      return;
    } else {
      setFile(selectedFile);
    }
  };

  const addAttachmentOnClick = async (event) => {
    event.preventDefault();
    const fileTypeValue = event.target.fileType.value;
    try {
      const requestPromise = addAttachment({
        file,
      }).unwrap();

      toast.promise(requestPromise, {
        loading: "Uploading file...",
        success: "File uploaded Successfully...!",
        error: (err) =>
          `File Uploading failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });

      const response = await requestPromise;

      if (response?.guid) {
        await addCandidateAttachmentOnClick(response?.guid, fileTypeValue);
      }
      setFile(null);
    } catch (error) {
      console.log(error);
    }
  };

  const addCandidateAttachmentOnClick = async (attachmentGuid, fileType) => {
    try {
      const requestPromise = addCandidateAttachment({
        attachmentTypeId: fileType,
        attachmentGuid: attachmentGuid,
      }).unwrap();
      toast.promise(requestPromise, {
        loading: "Saving your file...",
        success: "File Saved Successfully...!",
        error: (err) =>
          `File Save failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
      await requestPromise;
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  const removeCandidateAttachmentOnClick = async (attachmentGuid) => {
    try {
      const requestPromise = removeCandidateAttachment(attachmentGuid).unwrap();

      toast.promise(requestPromise, {
        loading: "Deleting your file...",
        success: "File Deleted Successfully...!",
        error: (err) =>
          `File Delete failed: ${
            err?.Message || err?.data?.Message || err?.data?.title
          }`,
      });
      setSelectedAttachmentGuid("");
    } catch (error) {
      console.log(error);
    }
  };
  const getAttachment = async (guid) => {
    try {
      const result = await getFile(guid);
      const url = window.URL.createObjectURL(result?.data);
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <div className="profile-wrapper">
      {candidateDetailsIsLoading && <LoadingSpinner />}
      <ToastWrapper />
      <ProfilePicture modal={isOpen} setModal={setIsOpen} />
      <div className="hero-section"></div>
      <div className="profile-container">
        <div className="user-profile-container">
          <div className="user-profile-content ">
            <img
              src={
                currentUser?.guid
                  ? getCandidateProfilePictureUrl(currentCandidateData?.guid)
                  : profilePicture
              }
              alt="avatar"
              role="button"
              title="Click to change your profile picture"
              onClick={() => {
                if (!isLoggedIn) {
                  errorToast(
                    "Please Create your profile to add a profile picture...!"
                  );
                } else {
                  setIsOpen((prev) => !prev);
                }
              }}
            />
            <div className="user-details">
              <h5 className="username">{`${currentCandidateData?.firstName} ${currentCandidateData?.lastName}`}</h5>
              {/* <p className="designation">Full Stack Developer</p>
              <p className="address">
                <img src={locationPin} alt="location" width={15} />
                United Kingdom
              </p> */}
            </div>
          </div>
        </div>
        <div className="profile-forms">
          <Nav card fill justified>
            {[
              { id: 1, name: "Basic Information" },
              { id: 2, name: "Professional Details" },
              { id: 4, name: "Attachments" },
            ].map((nav) => (
              <NavItem key={nav.id}>
                <button
                  className={`btn-profile-navigation ${
                    tab === nav.id ? "active" : ""
                  }`}
                  onClick={() => setTab(nav.id)}
                  disabled={nav.id !== 1 && !isLoggedIn}
                >
                  {[1, 2, 4].includes(nav.id) && (nav.id === 1 || isLoggedIn)
                    ? nav.name
                    : ""}
                </button>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId={1}>
              <div className="detials-form-container">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control profile-form-input"
                        id="firstName"
                        name="firstName"
                        value={basicInformation?.firstName}
                        onChange={(e) => handleBasicInfoInputChange(e)}
                      />
                      <label
                        htmlFor="firstName"
                        className="profile-form-input-label"
                      >
                        First Name *
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control profile-form-input"
                        id="lastName"
                        name="lastName"
                        value={basicInformation?.lastName}
                        onChange={(e) => handleBasicInfoInputChange(e)}
                      />
                      <label
                        htmlFor="lastName"
                        className="profile-form-input-label"
                      >
                        Last Name *
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control profile-form-input"
                        id="email"
                        name="email"
                        value={basicInformation?.email}
                        onChange={(e) => handleBasicInfoInputChange(e)}
                        disabled={isLoggedIn}
                      />
                      <label
                        htmlFor="email"
                        className="profile-form-input-label"
                      >
                        Email *
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="tel"
                        className="form-control profile-form-input"
                        id="mobile"
                        value={basicInformation?.mobile}
                        onChange={(e) => handleBasicInfoInputChange(e)}
                        name="mobile"
                      />
                      <label
                        htmlFor="mobile"
                        className="profile-form-input-label"
                      >
                        Mobile
                      </label>
                    </div>
                  </div>
                  {!isLoggedIn && (
                    <>
                      <div className="col-12 col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="password"
                            className="form-control profile-form-input"
                            id="password"
                            name="password"
                            onChange={(e) => handleBasicInfoInputChange(e)}
                          />
                          <label
                            htmlFor="password"
                            className="profile-form-input-label"
                          >
                            Password *
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="password"
                            className="form-control profile-form-input"
                            id="passwordAgain"
                            name="passwordAgain"
                            onChange={(e) => setAgainPassword(e.target.value)}
                          />
                          <label
                            htmlFor="passwordAgain"
                            className="profile-form-input-label"
                          >
                            Password Again *
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-12 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control profile-form-input"
                        id="address"
                        name="address"
                        value={basicInformation?.address}
                        onChange={(e) => handleBasicInfoInputChange(e)}
                      />
                      <label
                        htmlFor="address"
                        className="profile-form-input-label"
                      >
                        Address
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-2">
                  {isLoggedIn ? (
                    <>
                      <button
                        type="reset"
                        className="btn btn-outline-light btn-navigation"
                        onClick={handleBasicInfoInputResetToInitialValues}
                      >
                        Reset
                      </button>

                      <button
                        type="submit"
                        className="btn btn-outline-dark btn-navigation"
                        onClick={handleUpdateProfile}
                      >
                        Update Profile
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="reset"
                        className="btn btn-outline-light btn-navigation"
                        onClick={handleBasicInfoInputReset}
                      >
                        Clear
                      </button>
                      <button
                        type="submit"
                        className="btn btn-outline-dark btn-navigation"
                        onClick={handleCreateProfile}
                      >
                        Create Profile
                      </button>
                    </>
                  )}
                </div>
              </div>
            </TabPane>
            <TabPane tabId={2}>
              <div className="detials-form-container">
                <div className="row">
                  <div className="mb-3">
                    <p>Your Skills</p>
                    <CandidateSkill
                      isUpdate={update}
                      initialSelectedSkills={currentCandidateData?.skills ?? []}
                    />
                  </div>
                  <div>
                    <p>Experience</p>
                    <div className="row">
                      <div className="col">
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control profile-form-input"
                              id="jobTitle"
                              name="title"
                              value={experience?.title}
                              onChange={(e) =>
                                handleExperienceInputChange(e.target)
                              }
                            />
                            <label
                              htmlFor="jobTitle"
                              className="profile-form-input-label"
                            >
                              Job Title *
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control profile-form-input"
                                id="company"
                                name="company"
                                value={experience?.company}
                                onChange={(e) =>
                                  handleExperienceInputChange(e.target)
                                }
                              />
                              <label
                                htmlFor="companyName"
                                className="profile-form-input-label"
                              >
                                Company Name
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <Multiselect
                              options={workingTypes ?? []}
                              selectedValues={experience?.workType}
                              onSelect={(selectedList, selectedItem) =>
                                handleSelect(
                                  "workType",
                                  selectedList,
                                  selectedItem
                                )
                              }
                              onRemove={(selectedList, selectedItem) =>
                                handleDeSelect(
                                  "workType",
                                  selectedList,
                                  selectedItem
                                )
                              }
                              singleSelect
                              displayValue="name"
                              closeOnSelect={true}
                              placeholder={`Select Work Type`}
                              className="form-control skill-select-box"
                              avoidHighlightFirstOption="true"
                              style={{
                                chips: {
                                  background: "#1a1c46",
                                  color: "#fff",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-floating mb-3">
                                <DatePickerComponent
                                  id="fromDate"
                                  cssClass="form-control profile-form-input"
                                  format="dd-MM-yyyy"
                                  placeholder="dd-mm-yyyy"
                                  value={new Date(experience?.startDate)}
                                  onChange={(e) =>
                                    handleExperienceInputChange({
                                      name: "startDate",
                                      value: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="fromDate"
                                  className="profile-form-input-label"
                                >
                                  From
                                </label>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="form-floating mb-1">
                                <DatePickerComponent
                                  id="toDate"
                                  cssClass="form-control profile-form-input"
                                  value={
                                    experience?.endDate
                                      ? new Date(experience?.endDate)
                                      : null
                                  }
                                  format="dd-MM-yyyy"
                                  placeholder="dd-mm-yyyy"
                                  onChange={(e) =>
                                    handleExperienceInputChange({
                                      name: "endDate",
                                      value: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="toDate"
                                  className="profile-form-input-label"
                                >
                                  To
                                </label>
                              </div>
                              <div className="form-floating mb-3">
                                <div className="d-flex">
                                  <input
                                    type="checkbox"
                                    name="isCurrentlyWorkingOn"
                                    checked={experience?.isCurrentlyWorkingOn}
                                    onChange={(e) =>
                                      handleExperienceInputChange({
                                        name: "isCurrentlyWorkingOn",
                                        value: e.target.checked,
                                      })
                                    }
                                  />
                                  <label htmlFor="" className="mx-2">
                                    Are you currently working on this position ?
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <Multiselect
                              options={skills ?? []}
                              selectedValues={experience?.skills}
                              onSelect={handleAddSkill}
                              onRemove={handleRemoveSkill}
                              displayValue="name"
                              closeOnSelect={true}
                              placeholder={`Select Skills`}
                              className="form-control skill-select-box"
                              avoidHighlightFirstOption="true"
                              style={{
                                chips: {
                                  background: "#1a1c46",
                                  color: "#fff",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <textarea
                              className="form-control profile-form-input"
                              id="responsibilities"
                              rows={10}
                              name="description"
                              value={experience?.description}
                              onChange={(e) =>
                                handleExperienceInputChange(e.target)
                              }
                            ></textarea>
                            <label htmlFor="responsibilities">
                              Responsibilities
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end gap-2">
                          <button
                            type="reset"
                            className="btn btn-outline-light btn-navigation"
                            onClick={handleExperienceInfoInputReset}
                          >
                            {experience?.guid ? "Cancel" : "Clear"}
                          </button>
                          {experience?.guid ? (
                            <button
                              type="submit"
                              className="btn btn-outline-dark btn-navigation"
                              onClick={() =>
                                handleUpdateWorkingExperience(experience?.guid)
                              }
                            >
                              Update Experience
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-outline-dark btn-navigation"
                              onClick={handleAddWorkingExperience}
                            >
                              Add Experience
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="vr p-0"></div>
                      <div className="col">
                        <div className="experience-container">
                          {currentCandidateData?.experiences &&
                          currentCandidateData?.experiences?.length > 0
                            ? currentCandidateData?.experiences?.map((exp) => (
                                <div
                                  className="card experience-card"
                                  key={exp?.guid}
                                >
                                  <div className="card-header bg-white border-0 ">
                                    <div className="d-flex justify-content-between">
                                      <p className="m-0 fw-bold">
                                        {exp?.title}
                                      </p>
                                      <div className="d-flex gap-2 align-items-center justify-content-center ">
                                        <button
                                          className="btn btn-sm p-0"
                                          onClick={() =>
                                            handleEditExperience(exp)
                                          }
                                        >
                                          <img
                                            src={editIcon}
                                            alt="edit"
                                            width={21}
                                          />
                                        </button>
                                        <button
                                          className="btn btn-sm p-0"
                                          onClick={() =>
                                            handleDeleteWorkingExperience(
                                              exp?.guid
                                            )
                                          }
                                        >
                                          <img src={deleteIcon} alt="delete" />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between py-1">
                                      <small>{exp?.company}</small>
                                      <small className="border-bottom ">
                                        {exp?.workType?.name}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="card-body p-0 px-3">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <small>
                                          {exp?.startDate &&
                                            format(
                                              new Date(exp?.startDate),
                                              "dd-MM-yyyy"
                                            )}
                                        </small>
                                        {" - "}
                                        <small>
                                          {exp?.endDate &&
                                            format(
                                              new Date(exp?.endDate),
                                              "dd-MM-yyyy"
                                            )}
                                        </small>
                                      </div>
                                      <div>
                                        <small>
                                          {getMonthYearRange(
                                            exp?.startDate,
                                            exp?.endDate
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                    <p className="m-0 py-2">
                                      {exp?.description}
                                    </p>
                                  </div>
                                  <div className="card-footer bg-white border-0 ">
                                    <div className="d-flex gap-1 flex-wrap pb-2">
                                      {exp?.skills?.length > 0 &&
                                        exp?.skills?.map((skill) => (
                                          <small
                                            className="skill-label"
                                            key={skill?.guid}
                                          >
                                            {skill?.name}
                                          </small>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : "No available Working Experiences."}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>

            <TabPane tabId={4}>
              <div className="detials-form-container">
                <div className="row">
                  <div className="col-auto">
                    <label
                      className="file-upload-container"
                      htmlFor="uploadFile"
                      title="Click Here to Upload Documents"
                    >
                      <input
                        type="file"
                        id="uploadFile"
                        accept="application/pdf"
                        onChange={handleFileChange}
                      />
                      <img src={file ? pdfIcon : pdfIconLight} alt="PDF" />
                      <span>Browse Documents</span>
                      <span>*.PDF only</span>
                    </label>
                    <div className="relative p-1 d-flex align-items-center">
                      <span className="file-name">{file?.name}</span>
                      {file && (
                        <>
                          <button
                            className="btn btn-sm action-close-btn action-rounded"
                            onClick={() => setFile(null)}
                          >
                            <img src={closeIcon} alt="" />
                          </button>
                          <div className="dropdown ">
                            <button
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-auto-close="outside"
                              className="btn btn-sm action-check-btn action-rounded mx-1"
                            >
                              <img
                                src={uploadIcon}
                                alt=""
                                width={20}
                                height={19}
                              />
                            </button>
                            <form
                              className="dropdown-menu file-type-dropdown px-2  "
                              onSubmit={addAttachmentOnClick}
                            >
                              <div className="mb-3">
                                <label
                                  htmlFor="fileType"
                                  className="form-label"
                                >
                                  File Type
                                </label>
                                <select
                                  id="fileType"
                                  className="form-select form-select-sm profile-form-input"
                                  name="fileType"
                                >
                                  <option value={2}>Resume</option>
                                  <option value={3}>Cover Letter</option>
                                </select>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-sm btn-clear text-white float-end"
                              >
                                Upload
                              </button>
                            </form>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div>
                        {candidateResumes?.length > 0 && (
                          <h6 className="border-bottom pb-1">Resumes</h6>
                        )}
                        <div className="file-container">
                          {candidateResumes?.length > 0 &&
                            candidateResumes.map((attachement) => (
                              <div
                                className="file-details"
                                key={attachement?.guid}
                              >
                                <input
                                  id={`file-${attachement?.guid}`}
                                  className="form-check-input pdf-checkbox"
                                  type="checkbox"
                                  style={{ width: 0, height: 0 }}
                                  onClick={() =>
                                    setSelectedAttachmentGuid(attachement?.guid)
                                  }
                                />
                                <label
                                  htmlFor={`file-${attachement?.guid}`}
                                  className={`d-flex flex-column attachment ${
                                    selectedAttachmentGuid === attachement?.guid
                                      ? "selected-file"
                                      : ""
                                  } `}
                                >
                                  <img
                                    src={pdfIcon}
                                    className="pdf-icon"
                                    alt=""
                                    width={66}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="file-name ">
                                    {attachement?.fileName}
                                  </span>
                                </label>
                                <div
                                  className={`file-actions ${
                                    selectedAttachmentGuid === attachement?.guid
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm remove-attachment p-0"
                                    title="Delete File"
                                    onClick={() =>
                                      removeCandidateAttachmentOnClick(
                                        attachement?.attachmentGuid
                                      )
                                    }
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm  view-file-btn p-0"
                                    title="
                                View File"
                                    onClick={() =>
                                      getAttachment(attachement?.attachmentGuid)
                                    }
                                  >
                                    <img src={viewIcon} alt="" />
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div>
                        {candidateCoverLetters?.length > 0 && (
                          <h6 className="border-bottom pb-1">Cover Letters</h6>
                        )}
                        <div className="file-container">
                          {candidateCoverLetters?.length > 0 &&
                            candidateCoverLetters.map((attachement) => (
                              <div
                                className="file-details"
                                key={attachement?.guid}
                              >
                                <input
                                  id={`file-${attachement?.guid}`}
                                  className="form-check-input pdf-checkbox"
                                  type="checkbox"
                                  style={{ width: 0, height: 0 }}
                                  onClick={() =>
                                    setSelectedAttachmentGuid(attachement?.guid)
                                  }
                                />
                                <label
                                  htmlFor={`file-${attachement?.guid}`}
                                  className={`d-flex flex-column ${
                                    selectedAttachmentGuid === attachement?.guid
                                      ? "selected-file"
                                      : ""
                                  } `}
                                >
                                  <img
                                    src={pdfIcon}
                                    className="pdf-icon"
                                    alt=""
                                    width={66}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="file-name ">
                                    {attachement?.fileName}
                                  </span>
                                </label>
                                <div
                                  className={`file-actions ${
                                    selectedAttachmentGuid === attachement?.guid
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm remove-attachment p-0"
                                    title="Delete File"
                                    onClick={() =>
                                      removeCandidateAttachmentOnClick(
                                        attachement?.attachmentGuid
                                      )
                                    }
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm  view-file-btn p-0"
                                    title="
                                View File"
                                    onClick={() =>
                                      getAttachment(attachement?.attachmentGuid)
                                    }
                                  >
                                    <img src={viewIcon} alt="" />
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
