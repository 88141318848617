import { React, useState, useEffect, memo } from "react";
import { useGetSkillsQuery } from "../../features/staticData/staticData";
import {
  useAddCandidateSkillMutation,
  useRemoveCandidateSkillMutation,
} from "../../features/candidate/candidateSlice";
import Multiselect from "multiselect-react-dropdown";
import ToastWrapper, {
  successToast,
  errorToast,
} from "../../components/common/ToastWrapper/ToastWrapper";

const CandidateSkill = memo(
  ({
    initialSelectedSkills,
    isUpdate,
    section,
    setSelectedSkillGuids,
    name,
  }) => {
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState("");
    const [removedSkill, setRemovedSkill] = useState("");

    const [
      addCandidateSkill,
      { isLoading: addCandidateSkillIsLoading, addCandidateSkillIsSuccess },
    ] = useAddCandidateSkillMutation();

    const [
      removeCandidateSkill,
      {
        isLoading: removeCandidateSkillIsLoading,
        removeCandidateSkillIsSuccess,
      },
    ] = useRemoveCandidateSkillMutation();

    const {
      data: candidateSkills,
      isLoading: candidateSkillsIsLoading,
      isFetching: candidateSkillsIsFetching,
      error: candidateSkillsError,
    } = useGetSkillsQuery();

    const handleSkillAdd = (selectedList, selectedItem) => {
      setSelectedSkills((prevSkills) => [
        ...new Set([...prevSkills, selectedItem]),
      ]);
      setSelectedSkill(selectedItem?.guid);
    };

    const handleSkillRemove = (selectedList, removedItem) => {
      setSelectedSkills((prevSkills) =>
        prevSkills.filter((skill) => skill?.guid !== removedItem.guid)
      );
      setRemovedSkill(removedItem?.guid);
    };

    useEffect(() => {
      if (candidateSkills) {
        setSkills(candidateSkills);
      }
      if (candidateSkillsError) {
        return;
      }
    }, [candidateSkills, candidateSkillsError]);

    useEffect(() => {
      if (section) {
        const skillGuids = Array.isArray(selectedSkills)
          ? selectedSkills.map((skill) => skill?.guid)
          : [];
        setSelectedSkillGuids(skillGuids);
      }
    }, [section, selectedSkills]);

    useEffect(() => {
      if (isUpdate) {
        setSelectedSkills(initialSelectedSkills);
      }
      if (section) {
        setSelectedSkills(initialSelectedSkills);
      }
    }, [isUpdate, initialSelectedSkills, section]);

    useEffect(() => {
      if (selectedSkill && !section) {
        addCandidateSkillOnClick();
      }
      if (removedSkill && !section) {
        removeCandidateSkillOnClick();
      }
    }, [selectedSkill, removedSkill]);

    const addCandidateSkillOnClick = async () => {
      try {
        const response = await addCandidateSkill({
          guids: [selectedSkill],
        }).unwrap();
        if (response?.error) {
          errorToast(response?.error?.data?.title || "Something Went wrong");
          return;
        }
        setSelectedSkill("");
      } catch (error) {
        errorToast(error?.data?.Message);
        setSelectedSkill("");
      }
    };

    const removeCandidateSkillOnClick = async () => {
      try {
        const response = await removeCandidateSkill({
          guid: removedSkill,
        }).unwrap();
        if (response?.error) {
          errorToast(response?.error?.data?.title || "Something Went wrong");
          return;
        }
        setRemovedSkill("");
      } catch (error) {
        errorToast(error?.data?.Message);
        setRemovedSkill("");
      }
    };

    return (
      <div className="form-floating">
        <Multiselect
          options={skills}
          selectedValues={selectedSkills}
          onSelect={handleSkillAdd}
          onRemove={handleSkillRemove}
          displayValue="name"
          closeOnSelect={true}
          placeholder={`Select Skills`}
          className="form-control skill-select-box"
          avoidHighlightFirstOption="true"
          style={{
            chips: {
              background: "#1a1c46",
            },
          }}
        />
      </div>
    );
  }
);

export default CandidateSkill;
