import React, { useRef } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials, setCandidate } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import ToastWrapper, {
  errorToast,
} from "../../components/common/ToastWrapper/ToastWrapper";
import { apiSlice as api } from "../../app/api/apiSlice";

const LoginPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isError, isSuccess, isLoading, error }] = useLoginMutation();

  const fetchCandidateDetails = async () => {
    const result = await dispatch(api.endpoints.getCandidateDetails.initiate());

    if (result.error) {
      console.error("Failed to fetch candidate details:", result?.error);
    } else {
      dispatch(setCandidate(result?.data));
    }
  };

  const handleLogIn = async (e) => {
    e.preventDefault();
    let email = emailRef.current.value;
    let password = passwordRef.current.value;
    const canSave = email && password;
    if (canSave) {
      try {
        const userData = await login({ email, password }).unwrap();
        dispatch(setCredentials(userData));
        if (userData) {
          await fetchCandidateDetails();
        }
        email = "";
        password = "";
        navigate("/find-jobs");
      } catch (err) {
        errorToast(
          err?.data?.Message ||
            err?.data?.title ||
            "Someting went wrong. Please check your email & password"
        );
      }
    } else {
      errorToast("Email & Password cannot be empty..!");
    }
  };

  return (
    <div className="loginBackground">
      <ToastWrapper />
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-10 col-md-8 col-lg-4">
            <div className="card" style={{ marginTop: "20%" }}>
              {isLoading ? <LoadingSpinner /> : ""}
              <div className="card-body">
                <h3 className="logTitle">Let's get Started</h3>
                <h6 className="logSubtitle">Please log in to continue</h6>
                <form onSubmit={handleLogIn}>
                  <div className="form-container mt-5">
                    <div className="mb-4 row justify-content-center">
                      <div className="col-11">
                        <input
                          ref={emailRef}
                          type="email"
                          className="form-control loginEmail"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-1 row justify-content-center">
                      <div className="col-11">
                        <input
                          ref={passwordRef}
                          type="password"
                          className="form-control loginPassword"
                          placeholder="Password"
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-4 row justify-content-center">
                      <div className="col-11 text-end">
                        <Link
                          className="btn btn-link"
                          style={{ textDecoration: "none" }}
                        >
                          Forgot Password!
                        </Link>
                      </div>
                    </div>
                    <div className="mb-3 row justify-content-center">
                      <div className="col-11">
                        <button type="submit" className="custom-btn logInBtn">
                          Log In
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 row justify-content-center">
                      <div className="col-11">
                        <div className="row">
                          <div className="col-sm-12 col-md-6 mb-3">
                            <button
                              type="reset"
                              className="custom-btn cancelBtn"
                              onClick={() => navigate("/find-jobs")}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <button
                              className="custom-btn signUpBtn"
                              onClick={() => navigate("/create-profile")}
                            >
                              Create Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
