import React from "react";
import toast, { Toaster } from "react-hot-toast";

const ToastWrapper = () => {
  return (
    <>
      <Toaster
        containerStyle={{
          top: "13%",
        }}
        toastOptions={{
          success: {
            style: {
              minWidth: "70vw",
            },
            className: "bg-success text-white",
          },
          error: {
            style: {
              minWidth: "70vw",
            },
            className: "bg-danger text-white",
          },
          loading: {
            style: {
              minWidth: "70vw",
              background: "#4b4870",
              color: "#fff",
            },
          },
        }}
      />
    </>
  );
};

export const successToast = (msg) => {
  toast.success(msg);
};

export const errorToast = (msg, duration) => {
  toast.error(msg, duration);
};

export default ToastWrapper;
